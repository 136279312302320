import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import anime from 'animejs';

export function index(){
	
	const video = document.getElementById('video');
	if(video){
		video.play();
	}
	
	const indexswipe = document.getElementById('fiveSwiper');
	if(indexswipe){
		new Swiper(indexswipe, {
			slidesPerView: 'auto',
			loop: true,
			speed: 800,
			modules: [Navigation, Pagination, Autoplay],
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			pagination: {
				el: document.getElementById('fiveSwiperPager'),
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + "</span>";
				},
			},
		});
	}
};