import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {loading} from './loading';
import {index} from './index';
import {vm} from './vm';


document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    vm();
    scrolEvents(controller , scene);
	
    // LOADING 処理
    const keyName = 'visited';
    const keyValue = true;
	const intro = document.getElementById('loadView');

    if (!sessionStorage.getItem(keyName)) {
        sessionStorage.setItem(keyName, keyValue);
        loading(intro);
    }else{
        if(intro){
            intro.remove();
        }
        index();
    }
	
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
	window.addEventListener('resize', () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

});