import Vue from 'vue/dist/vue.min';
import {modal} from './components/modal';

export function vm(){
    const v = new Vue({
        el: '#site-header',
		data: {
			sp: false
		}
    });
	
    const iv = new Vue({
        el: '#indexapp',
        components: {
            modal: modal()
        }
    });
};