import {index} from './index';

export function loading(el){
	beforeEvent(el);
    let img = document.getElementsByTagName('img');
    let imgCount = img.length;
    let loadCount = 0;
	
    for(let i = 0; i < imgCount; i++){
        let imgObj = new Image;
        imgObj.onload = ()=>{
            loadCount ++;
            if(loadCount == imgCount){
                completeEvent(el);
            }
        };
        imgObj.src = img[i].src;
    }
};

function beforeEvent(el){
	el.classList.add('active');
}

function completeEvent(el){
    setTimeout(() => {
        el.classList.add('remove');
        index();
    }, 3000);
	
	el.addEventListener('animationend',(e) => {
		if(e.animationName == 'removed'){
			e.target.remove();
		}
	});
}