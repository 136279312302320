export function modal(){
    const temp = {
        data: function(){
            return {
                view: false,
				modaldata: '',
            }
        },
		methods: {
			modalview: function(data){
				this.modaldata = data;
				this.view = true
			},
			eng: function(numb){
				return '環境整備 ' + numb;
			}
		},
		props: {
			list: {
				type: Array,
      			required: true,
			}
		},
        template: 
        `
			<div class="second__listable">
				<ul class="second__listable__list">
					<li class="second__listable__list__item" v-for="item, index in list" :key="index" @click="modalview(item.modal)">
						<figure><img :src="item.pics" :alt="item.title"></figure>
						<dl>
							<dt v-html="item.title" :data-eng="eng(index + 1)"></dt>
							<dd v-html="item.desc"></dd>
						</dl>
						<div class="dispnone" v-html="item.modal"></div>
					</li>
				</ul>
				<transition name="fadeto">
					<div class="overlay" v-if="view" v-cloak>
						<div class="overlay__item">
							<div class="overlay__item__close" @click="view = false"><i class="fa-solid fa-xmark"></i></div>
							<div class="overlay__item__box">
								<div v-html="modaldata"></div>
								<div class="overlay__item__btn btnarea center">
									<a href="/env/" class="radius arrow">環境整備の事例を見る</a>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</div>
        `
    }
    return temp;
};